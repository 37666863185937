import React, { Component } from 'react';


class term extends Component{

   constructor(props){
   	super(props)


   }

   render(){

   	return(
     
          <div className>

             <p> hi there, will add the content shortly </p> 
          </div>
   		)
   }


}

export default term