import React, {Component} from 'react';
import test from '../../images/test.gif';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';



class canvasB extends Component { 

  constructor(props){
     super(props)

  }

  componentDidMount(){

    function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
      }


    this.props.taskStatus(true)
    
    const canvas = this.refs.canva;
    const ctx = canvas.getContext("2d");
    const img = this.refs.kids;
    const heading = this.props.heading;
    const visualA = this.refs.visualA; 
    const visualB = this.refs.visualB;
    const visualC = this.refs.visualC; 
    const visualD = this.refs.visualD;
    const explanationA = this.props.explanationA;
    const explanationB = this.props.explanationB;
    const explanationC = this.props.explanationC;
    const explanationD = this.props.explanationD;

    console.log(visualA)
    console.log(this.props.visua)

     visualA.onload = () => {

      ctx.drawImage(visualA, 20, 180,  110, 90); 
    
   
      ctx.font = "13px source code pro";
      ctx.textAlign = "center";
       const widthmax = 100
      const eAX = 80
      const eAY = 300
      var lineheight = 15;
      wrapText(ctx, explanationA, eAX, eAY, widthmax, lineheight);
   
      const eBX = 240
      const eBY = 300
      wrapText(ctx, explanationB, eBX, eBY, widthmax, lineheight);
   
      const eCX = 420
      const eCY = 300
      wrapText(ctx, explanationC, eCX, eCY, widthmax, lineheight);
   
      const dMaxWidth = 400
      const eDX = 720
      const eDY = 500
      wrapText(ctx, explanationD, eDX, eDY, dMaxWidth, lineheight);

      }
    

  
   visualB.onload = () => { 
     ctx.drawImage(visualB, 200, 180, 90, 90); 
     ctx.font ="30px crosshatcher";
     ctx.textAlign = "left"
     ctx.fillText(heading, 240, 50);    
    }

   visualC.onload = () => {
     ctx.drawImage(visualC, 360, 180, 90, 90); 
   }

   visualD.onload = () => { 
   ctx.drawImage(visualD, 450, 150, 350, 350); 
  }

    img.onload = () => { 
     ctx.drawImage(img, 5, 10, 50, 50); 

    }

  }

 componentDidUpdate(){ 

      function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
      }

    const canvas = this.refs.canva;
    const ctx = canvas.getContext("2d");
    const heading = this.props.heading;
    const visualA = this.refs.visualA; 
    const visualB = this.refs.visualB;
    const visualC = this.refs.visualC; 
    const visualD = this.refs.visualD;
    const explanationA = this.props.explanationA;
    const explanationB = this.props.explanationB;
    const explanationC = this.props.explanationC;
    const explanationD = this.props.explanationD;



   visualA.onload = () => { 
      ctx.drawImage(visualA, 20, 180,  110, 90); 
   
      ctx.font = "16px source code pro";
      ctx.textAlign = "center"
    const widthmax = 100
      const eAX = 80
      const eAY = 300
      var lineheight = 15;
      wrapText(ctx, explanationA, eAX, eAY, widthmax, lineheight);


      const eBX = 240
      const eBY = 300
      wrapText(ctx, explanationB, eBX, eBY, widthmax, lineheight);

      const eCX = 420
      const eCY = 300
      wrapText(ctx, explanationC, eCX, eCY, widthmax, lineheight);
 
      const dMaxWidth = 400
     const eDX = 720
      const eDY = 500
      wrapText(ctx, explanationD, eDX, eDY, dMaxWidth, lineheight);
    }



   visualB.onload = () => { 
     
      ctx.drawImage(visualB, 200, 180, 90, 90); 
     ctx.font ="30px crosshatcher";
     ctx.textAlign = "left"
     ctx.fillText(heading, 240, 50);    
    }

  visualC.onload = () => { 
      ctx.drawImage(visualC, 360, 180, 90, 90); 
   }

  visualD.onload = () => { 
     ctx.drawImage(visualD, 450, 100, 350, 350); 
   }

  }

  
responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }



  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 
       <canvas className="B" ref="canva" width={this.props.width} height={this.props.height} />
       <img ref="kids" src={coverKids} className="hidden" />
       <img ref="visualA" src={this.props.visualA}  className="hidden" /> 
       <img ref="visualB" src={this.props.visualB} className="hidden" />
       <img ref="visualC" src={this.props.visualC} className="hidden" />
        <img ref="visualD" src={this.props.visualD} className="hidden" />
     </div>
  		)
  }
}

export default canvasB