import React from 'react';
import {Link} from 'react-router-dom'; 
import backwardSwitchButton from '../images/back.png';


const button = (props) => {

	return(
	  <Link to={`/lab/${props.prevTopicRef}/lessons/${props.prevResourceRef}`}>                                                              
             <img src={backwardSwitchButton} width="40" onClick={props.byteDecrease}/> 
           </Link> 
       )
}

export default button;