import React from 'react';
import {Link} from 'react-router-dom'; 
import switchButton from '../images/next.png';


const button = (props) => {


	

	return(
	  <Link to={`/lab/${props.topicRef}/lessons/${props.resourceRef}`} >                                                              
             <img src={switchButton} onClick={props.currentProgress} width="40"/> 
           </Link> 
       )
}

export default button;