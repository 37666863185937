import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';


class submissionModal extends Component{

     constructor(props){
     	super(props)

     }


     render(){

         return(
<div>
        <Modal animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
       
        </Modal.Footer>
      </Modal>
      
      </div>
         	)
     }

}


export default submissionModal;


