import React from 'react';
import {Link} from 'react-router-dom'; 
import switchButton from '../images/next.png';


const button = (props) => {

      console.log(props)
	

	return(
	  <Link to={`/lab/${props.topicRef}/assessments/${props.taskRef}/category/${props.assessmentRef}`} >                                                              
             <img src={switchButton} onClick="" width="40"/> 
       </Link> 
       )
}

export default button;