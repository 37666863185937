import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';

import '../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';
import atclogo from '../images/loader.png'

    class Mirror extends Component{
      constructor() {
        super();
        this.state = {
          id: '',
          html: '',
        };

          this.pusher = new Pusher("df64cd31e16fc2ed7562", {
          cluster: "eu",
          forceTLS: true
        });

        this.channel = this.pusher.subscribe("ATC-development");
 
      }

      componentDidUpdate() {
        this.runCode();
      }

      componentDidMount() {
        this.setState({
          id: pushid(),
        });

        this.channel.bind("code-update", data => {
          const { id } = this.state;
          if (data.id === id) return;

          this.setState({
            html: data.html,
          });
        });
      }


        syncUpdates = () => {
        const data = { ...this.state };

        axios
          .post("http://localhost:3001/updateEditor", data)
          .catch(console.error);
      };

    
//running the editor code 
      runCode = () => {
        const {html} = this.state;

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>
           
            </style>
          </head>
          <body>
            ${html}
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
      };

//rendering the component as JSX 

      render() {
        const { html } = this.state;
        const codeMirrorOptions = {
          theme: 'shadowfox',
          lineNumbers: true,
          scrollbarStyle: null,
          lineWrapping: true,
        };

//jsx creation 

        return (
         <div className="container standard-padding-tb-2 black-background top-right-curve bottom-right-curve">
          <div className="row standard-padding-tb-2"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
              <img src={atclogo} width="100"/> 
              <figcaption className="yellow-background">
               <p className="accent-font"> Code Playground </p> 
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-10"> 

             </div> 
          </div> 
          <div className="App row">
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {
                    this.setState({ html }, () => this.syncUpdates());
                  }}
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>
          
          </div>
        );
      }
    }

    export default Mirror