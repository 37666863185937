import React, { Component } from 'react';

 class assessment extends Component { 
   constructor(props) { 
    super(props);

     this.state ={ }; 
   }


   render() { 

     return(


          <div> assessment </div>

     	)

   }
   

}

export default assessment