import React, {Component} from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';



class canvasI extends Component { 

  constructor(props){
     super(props)

     //create dedicated height and width variable
     const thinHeight = this.props.height
     const thinWidth = this.props.height / 1.7

             this.state = {
          id:'',
          html:'',
          title:'',
          height: thinHeight,
          width: thinWidth,
          taskDescription:'',
          positiveAlert:'',
          negativeAlert:'',
          taskByteValue:''
        };

  }

  componentDidMount(){

    
    const canvas = this.refs.canva;
    const ctx = canvas.getContext("2d");

  //  const visualB = this.refs.visualB; 

    const visualA = this.refs.visualA; 
    
   
  

    console.log(visualA)
    console.log(this.props.height)
    console.log(this.state.height)


   visualA.onload = () => { 
      ctx.drawImage(visualA, 50, 100,  310, 270);  
   //   ctx1.drawImage(visualB, 50, 150,  310, 270);  

 
    }




    this.props.taskStatus(false)
    //this.props.byteState(this.state.taskByteValue)

    this.setState({
            positiveAlert:false,
            negativeAlert:false
          })

  }

 componentDidUpdate(){ 
    const canvas = this.refs.canva;
    const ctx = canvas.getContext("2d");

    const visualA = this.refs.visualA; 

   

   //const visualB = this.refs.visualB; 
    
  

    
  



   visualA.onload = () => {    
      ctx.drawImage(visualA, 50, 100,  310, 270);
     // ctx.drawImage(visualB, 550, 150,  310, 270);
    }


  }



 async liftHtmlState(){
  let thtml =  this.state.html
  await thtml.trim()
  console.log(thtml)
  this.props.htmlChange(this.state.html)
 }


responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }


  render(){


        


    return(
      <div className="standard-padding-tb-skin-visual black-background canvasHeight"> 
      <div className="row">
      <div className="col-12">
       <div className="row">
      <div className="col-2">
      <img src={coverKids} width="50" />
      </div>
      <div className="col-6 offset-1 text-left">
       <h2 className="header-font white-text"> {this.props.heading} </h2>
      </div>
       </div>
       </div>

      <div className="col-6">
       <canvas className="C" ref="canva" width={this.state.width} height={this.state.height} />
       </div>
       <div className="col-6">
       <div className="col-12 frame-position">
       <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Home</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</a>
  </li>
</ul>
<div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <iframe src="https://inspireleadership.biz" title="W3Schools Free Online Web Tutorials" width="100%" /> 
  </div>
  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>

</div>
       
       </div>
       <div className="col-12 text-center">

       

       <div className="App row text-center" id="">
            

      
            <section className="playground col-12">
              <h5 className="body-font blue-text text-left">
                  {this.props.explanationA}
              </h5>  
             <h6 className="body-font blue-text text-left">
                  {this.props.explanationB}
              </h6> 

            
            </section>
             
         
          </div>
        </div>
       </div>
       </div>
       <img ref="kids" src={coverKids} className="hidden" />
       <img ref="visualA" src={this.props.visualA}  className="hidden" /> 
       <img ref="visualB" src={this.props.visualB}  className="hidden" />
     </div>
      )
  }
}

export default canvasI