import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import newProject from '../../images/new.png';
import continueProject from '../../images/continue.png'




class playgroundStart extends Component { 

   constructor(props) { 
    super(props);

     this.state ={ }; 
   }


   render() { 

     return(


        <> 

       <div className="row standard-padding-tb">
    
    <div className="col-12 text-center ">
        <h2 className="accent-font  white-text">  Welcome to playground  </h2>
        <h6 className="accent-font white-text">  Select an option to continue  </h6>

    </div>  

    <div className="col-12 text-center standard-padding-tb">

    
    <figure> 
      <img src={newProject} width="30%" />
     
      <figcaption> 
       
      <Link to="/playground/start/newproject" > 
       <button className="btn btn-success standard-round-box-curve green-background white-text header-font tabs-btn-hover">    Create a new project </button>
     </Link>
      </figcaption>  
    
    </figure>

 
    </div>   


       </div>

     </>

     	)

   }
   

}

export default playgroundStart;