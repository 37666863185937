import React from 'react';
import {Link} from 'react-router-dom'; 
import backwardSwitchButton from '../images/back.png';


const button = (props) => {

	return(
	  <Link to={`/lab/${props.topicRef}/assessments/${props.prevTaskRef}/category/${props.assessmentRef}`}>                                                              
             <img src={backwardSwitchButton} width="40" onClick=""/> 
           </Link> 
       )
}

export default button;