import React, {Component} from 'react';



class community extends Component { 


  constructor(props){
       super(props)
       this.state={

       }

     }


componentDidMount(){ 

    document.location.replace('/')

}


render(){


	return(

<div> </div>

		)
}

}


export default community