import React, {Component} from 'react';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';
import download from 'downloadjs'



class canvasj extends Component { 

  constructor(props){
     super(props)

     this.state={

           downloadname:this.props.downloadable
     }
          this.download = this.download.bind(this)
  }

  componentDidMount(){
    this.props.taskStatus(false)

  }


  async download (){

    await  download(`${this.state.downloadname}`)
     this.props.activateArrow()
      console.log(this.props)
      }

 componentDidUpdate(){ 
 }


  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 
       <div className="row">
           <div className="col-1"> 
           <img src={coverKids} width="70%"/>
           </div>

           <div className="col-11 text-center">
                <h4 className="header-font"> {this.props.heading} </h4>

           </div>
           </div>

           <div className="row">

           <div className="col-12 text-center"> 
                <img width="60%" src={this.props.visualA} />  
           </div>
           <div className="col-12 text-center"> 

              <h4 className="body-font"> {this.props.explanationA} </h4>
           
                <button  className="btn btn-danger" onClick={this.download} >
                    Download Resources
                      
                    </button>   
       
           </div>
           </div>
              
       </div>
   
    
  
  		)
  }
}
  export default canvasj