import React, {PureComponent} from 'react';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';




class canvasA extends PureComponent { 

  constructor(props){
     super(props)

     

  }

  componentDidMount(){



  function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
      }

  this.props.taskStatus(true)

  console.log(this.props)
  const canvas = this.refs.canva;
  const ctx = canvas.getContext("2d");
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;


    img.onload = () => { 
     ctx.drawImage(img, 5, 10, 50, 50); 
     ctx.font ="30px crosshatcher";
     ctx.textAlign = "left"
     ctx.fillText(topic, 240 , 50);

    }

    img2.onload = () => { 
       
      ctx.moveTo(170, 350);
      ctx.lineTo(350, 250);
      ctx.stroke();
      ctx.font = "14px source code pro"
      ctx.textAlign = "center"
      const widthmax = 300
      const x = 150
      const y = 250
      var lineheight = 15;
 wrapText(ctx, explanationA, x, y, widthmax, lineheight);
      //ctx.fillText(testText2, 150, 225);
      //ctx.fillText(testText3, 150, 245);
      //ctx.fillText(testText4, 150, 265);
      //ctx.fillText(testText5, 150, 285);
      ctx.drawImage(img2, 5, 270, 250, 250);
     
    }

       img3.onload = () => { 
      
      ctx.font = "14px source code pro";
      ctx.textAlign = "center"
      const widthmax = 250
      const x = 420
      const y = 330
      var lineheight = 15;
 wrapText(ctx, explanationB, x, y, widthmax, lineheight);
      //ctx.fillText(testText7, 430, 330);
      //ctx.fillText(testText8, 430, 350);
      //ctx.fillText(testText9, 430, 370);
      ctx.drawImage(img3, 350, 160, 150, 150);
     

      ctx.moveTo(500, 230);
      ctx.lineTo(620, 230);
      ctx.stroke();

      
    }

      img4.onload = () => {     
      ctx.font = "14px source code pro";
      ctx.textAlign = "center"
        const widthmax = 250
      const x = 670
      const y = 330
      var lineheight = 15;
 wrapText(ctx, explanationC, x, y, widthmax, lineheight);

     
      //ctx.fillText(testText7, 750, 330);
      //ctx.fillText(testText8, 750, 350);
      //ctx.fillText(testText9, 750, 370);
      ctx.drawImage(img3, 600, 160, 150, 150);     
    }


  }

  componentDidUpdate(){
  console.log(this.props)
  const canvas = this.refs.canva;
  const ctx = canvas.getContext("2d");
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;


    img.onload = () => { 
     ctx.drawImage(img, 5, 10, 50, 50); 
     ctx.font ="30px crosshatcher";
     ctx.textAlign = "left"
     ctx.fillText(topic, 240 , 50);

    }

    img2.onload = () => { 
       
      ctx.moveTo(170, 350);
      ctx.lineTo(350, 250);
      ctx.stroke();
      ctx.font = "14px source code pro"
      ctx.textAlign = "center"
      ctx.fillText(explanationA, 150, 205);
      //ctx.fillText(testText2, 150, 225);
      //ctx.fillText(testText3, 150, 245);
      //ctx.fillText(testText4, 150, 265);
      //ctx.fillText(testText5, 150, 285);
      ctx.drawImage(img2, 5, 270, 250, 250);
     
    }

       img3.onload = () => { 
      
      ctx.font = "14px source code pro";
      ctx.textAlign = "center"
      ctx.fillText(explanationB, 430, 310);
      //ctx.fillText(testText7, 430, 330);
      //ctx.fillText(testText8, 430, 350);
      //ctx.fillText(testText9, 430, 370);
      ctx.drawImage(img3, 350, 160, 150, 150);
     

      ctx.moveTo(530, 230);
      ctx.lineTo(650, 230);
      ctx.stroke();

      
    }

      img4.onload = () => {     
      ctx.font = "14px source code pro";
      ctx.textAlign = "center"
      ctx.fillText(explanationC, 750, 310);
      //ctx.fillText(testText7, 750, 330);
      //ctx.fillText(testText8, 750, 350);
      //ctx.fillText(testText9, 750, 370);
      ctx.drawImage(img3, 670, 160, 150, 150);     
    }


  }



responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }


  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 
       <canvas className="A" ref="canva" width={this.props.width} height={this.props.height} />
       <img ref="kids" src={coverKids} className="hidden" />
       <img ref="net" src={this.props.visualA}  className="hidden" /> 
       <img ref="web" src={this.props.visualB} className="hidden" />
       <img ref="web1" src={this.props.visualC} className="hidden" />
     </div>
  		)
  }
}

export default canvasA